import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Checkbox,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CustomToolTip from 'components/StylesTooltip';
import React, { FC, memo, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  resetBulkOnboardSchedulePayload,
  setBulkOnboardSchedulePayload,
  setModalPreference,
  setScheduleModalProps
} from '../../../store';
import {
  BulkOnboardInstancesInfo,
  BulkOnboardSchedulePayload,
  MountPointInfos,
  PlannedInstanceInfo,
  PlannedScheduleTriggerResponse,
  ScheduleModalProps
} from '../../../types';
import { PlannedVmDetailsProps } from '../../../types/types';
import InfoBox from '../../infoBox';
import ActionButtons from './ActionButtons';
import TableHeader from './TableHeader';
import Title from './Title';
import VolumeDetails from './volumeDetailsInnerTable';

const tooltipTitle =
  "Since the onboarding of volumes is in progress. Hence can't be rescheduled or cancelled.";

const StyledTableContainer = styled(Box)({
  height: 530,
  overflowY: 'auto'
});

const PlannedVmDetailTable: FC<PlannedVmDetailsProps> = (props) => {
  const { scheduledInstanceIndex } = props;
  const dispatch = useAppDispatch();
  const { plannedOnboarding } = useAppSelector((state) => state.unmanagedVm);
  const [selectedPlannedSchedule, setSelectedPlannedSchedule] =
    useState<PlannedScheduleTriggerResponse | null>(null);
  const [selectedInstanceId, setSelectedInstanceId] = useState<string[]>([]);
  const [selectedInstanceInfo, setSelectedInstanceInfo] = useState<PlannedInstanceInfo[]>([]);
  const [openInnerTableInstancId, set0penInnerTableInstancId] = useState<string[]>([]);

  useEffect(() => {
    setSelectedInstanceId([]);
    setSelectedInstanceInfo([]);
    if (plannedOnboarding?.plannedOnboardingData?.data)
      setSelectedPlannedSchedule(
        plannedOnboarding?.plannedOnboardingData?.data?.bulkScheduleTriggerResponseList[
          scheduledInstanceIndex
        ]
      );
  }, [scheduledInstanceIndex]);

  const disableToolTip = (instance: PlannedInstanceInfo) => {
    return instance?.mountPointInfos?.[0]?.partitionScheduleStatus !== 'IN_PROGRESS';
  };

  const getVolumeType = (mpInfos: MountPointInfos) => {
    if (mpInfos?.customRebootBasedOnboard) {
      return 'customReboot';
    }
    if (mpInfos?.mountPoint === '/' || mpInfos?.mountPoint === 'C') {
      return 'root';
    } else {
      return 'dataVolume';
    }
  };

  const constructPayload = (instanceInfo: PlannedInstanceInfo[]) => {
    let tenantAndInstance: BulkOnboardSchedulePayload | any = {};
    let payload = {};
    let scheduleModalProps: ScheduleModalProps = {
      scheduleInstanceInfo: [],
      totalSaving: 0,
      totalSavingWithConversion: 0,
      onboardType: null,
      customRebootBasedOnboard: false,
      cloudProviderList: [],

      onboardTimeGapInMinutes: 0,
      shrinkSupported: true,
      restartMessageDisabled: null,
      minimumTimeBeforeReboot: 0,
      popupInfo: null,
      isConversionCheckBoxChecked: true
    };

    if (selectedPlannedSchedule) {
      const rebootReq: boolean = selectedPlannedSchedule?.rebootRequired;
      payload = {
        parentBulkTriggerId: selectedPlannedSchedule?.bulkTriggerId,
        osType: selectedPlannedSchedule?.instanceInfo?.[0]?.platformType,
        supportingScheduleInfo: {
          rebootRequired: rebootReq,
          cleanupDelayDays: 3
        },
        ...(rebootReq
          ? { rebootStartTime: selectedPlannedSchedule?.rebootStartTime }
          : { scheduledStartTime: selectedPlannedSchedule?.scheduledStartTime })
      };

      // schedule onboard props
      const mpInfo = instanceInfo?.[0]?.mountPointInfos?.[0];
      scheduleModalProps = {
        ...scheduleModalProps,
        onboardTimeGapInMinutes: mpInfo?.onboardTimeGapInMinutes,
        customRebootBasedOnboard: rebootReq,
        minimumTimeBeforeReboot: mpInfo?.minTimeBeforeReboot,
        onboardType: getVolumeType(mpInfo)
      };
    }

    // api payload
    const mountPointInfo: BulkOnboardInstancesInfo[] = [];
    instanceInfo?.map((instance: PlannedInstanceInfo) => {
      let payloads: any = {};
      let scheduleModalpayloads: any = {};
      const temMountPointList: string[] = [];
      payloads = { tenantId: instance?.tenantId, instanceId: instance?.instanceId };
      scheduleModalpayloads = {
        instanceId: instance?.instanceId,
        osName: instance?.osName,
        instanceName: instance?.instanceName,
        osShortName: instance?.osName
      };

      instance.mountPointInfos.forEach((mpinfo: MountPointInfos) => {
        payloads = {
          ...payloads,
          mountPoint: mpinfo?.mountPoint,
          size: mpinfo?.size,
          onboardingDiskType: mpinfo?.onboardingDiskType
        };
        temMountPointList.push(mpinfo?.mountPoint);
        mountPointInfo.push(payloads);
      });
      scheduleModalProps?.scheduleInstanceInfo.push({
        ...scheduleModalpayloads,
        mountPoint: temMountPointList
      });
    });

    tenantAndInstance = { ...payload, mountPointInfo: mountPointInfo };
    dispatch(setBulkOnboardSchedulePayload(tenantAndInstance));
    dispatch(setScheduleModalProps(scheduleModalProps));
  };

  const [allcheck, setAllCheck] = useState<boolean>(false);
  const [interminentCheck, setInterminentCheck] = useState<boolean>(false);

  const handleAllCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      if (selectedPlannedSchedule?.instanceInfo) {
        constructPayload(selectedPlannedSchedule?.instanceInfo);
        setSelectedInstanceInfo(selectedPlannedSchedule?.instanceInfo);
        selectedPlannedSchedule?.instanceInfo?.map((instance: PlannedInstanceInfo) => {
          setSelectedInstanceId((prev: string[]) => [...prev, instance?.instanceId]);
        });
        setAllCheck(true);
      }
    } else {
      setAllCheck(false);
      setSelectedInstanceInfo([]);
      setSelectedInstanceId([]);
      dispatch(resetBulkOnboardSchedulePayload());
      dispatch(setScheduleModalProps(null));
    }
  };

  const handleSingleCheck = (instance: PlannedInstanceInfo) => {
    const name = instance?.instanceId;
    const selectedIndex = selectedInstanceId.indexOf(name);
    let newSelected: string[] = [];
    let tempInstance: PlannedInstanceInfo[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedInstanceId, name);
      tempInstance = tempInstance.concat(selectedInstanceInfo, instance);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedInstanceId.slice(1));
      tempInstance = tempInstance.concat(selectedInstanceInfo.slice(1));
    } else if (selectedIndex === selectedInstanceId.length - 1) {
      newSelected = newSelected.concat(selectedInstanceId.slice(0, -1));
      tempInstance = tempInstance.concat(selectedInstanceInfo.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedInstanceId.slice(0, selectedIndex),
        selectedInstanceId.slice(selectedIndex + 1)
      );
      tempInstance = tempInstance.concat(
        selectedInstanceInfo.slice(0, selectedIndex),
        selectedInstanceInfo.slice(selectedIndex + 1)
      );
    }
    setSelectedInstanceInfo(tempInstance);
    setSelectedInstanceId(newSelected);
    constructPayload(tempInstance);

    if (selectedPlannedSchedule) {
      setAllCheck(selectedPlannedSchedule?.instanceInfo.length === newSelected?.length);
      setInterminentCheck(selectedPlannedSchedule?.instanceInfo.length < newSelected?.length);
    }
  };

  const handleUpdateSchedule = () => {
    dispatch(setModalPreference('onboardUpdate'));
  };
  const handleCancelSchedule = () => {
    dispatch(setModalPreference('onboardCancel'));
  };

  const disableButtonCheck = () => {
    return selectedInstanceId?.length > 0 ? false : true;
  };

  const handleOpenInnerTable = (instanceId: string) => {
    if (openInnerTableInstancId.includes(instanceId)) {
      set0penInnerTableInstancId((prev) => prev.filter((iid) => iid !== instanceId));
    } else {
      set0penInnerTableInstancId((prev) => [...prev, instanceId]);
    }
  };

  return (
    <>
      <StyledTableContainer>
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {selectedPlannedSchedule && <Title selectedPlannedSchedule={selectedPlannedSchedule} />}
          <ActionButtons
            disableButtonCheck={disableButtonCheck}
            handleCancelSchedule={handleCancelSchedule}
            handleUpdateSchedule={handleUpdateSchedule}
            selectedInstanceId={selectedInstanceId}
          />
        </Box>

        <InfoBox
          content="Rescheduling/cancelling an instance onboarding can't be done after onboarding
            starts for any volume within it."
        />

        <TableContainer
          component={Paper}
          sx={{
            mt: 2,
            border: '1px solid #454546',
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
            borderRadius: '16px'
          }}>
          <Table>
            {selectedPlannedSchedule && (
              <TableHeader
                handleAllCheck={handleAllCheck}
                allcheck={allcheck}
                interminentCheck={interminentCheck}
              />
            )}
            <TableBody>
              {selectedPlannedSchedule &&
                selectedPlannedSchedule?.instanceInfo?.map((instance: PlannedInstanceInfo) => (
                  <React.Fragment key={instance?.instanceId}>
                    <TableRow
                      sx={{
                        '.MuiTableCell-root': {
                          color: 'rgba(255, 255, 255, 0.5)'
                        }
                      }}>
                      <TableCell
                        padding="checkbox"
                        sx={{ position: 'relative' }}
                        data-testid={instance?.instanceId}>
                        <CustomToolTip
                          text={tooltipTitle}
                          showIcon={false}
                          top={12}
                          left={4}
                          disable={disableToolTip(instance)}>
                          <Checkbox
                            checked={selectedInstanceId.includes(instance?.instanceId)}
                            onClick={() => handleSingleCheck(instance)}
                          />
                        </CustomToolTip>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ maxWidth: 200, wordBreak: 'break-word' }}>
                        {instance?.instanceName}
                      </TableCell>
                      <TableCell align="left">{instance?.osName}</TableCell>
                      <TableCell align="left">{instance?.noOfScheduledVolumes}</TableCell>

                      <TableCell
                        data-testid={instance?.instanceName}
                        align="center"
                        onClick={() => handleOpenInnerTable(instance?.instanceId)}
                        sx={{
                          borderLeft: '1px solid #454546',
                          maxWidth: 50,
                          cursor: 'pointer'
                        }}>
                        <IconButton
                          aria-label="expand row"
                          size="large"
                          sx={{
                            height: 28,
                            width: 28,
                            borderRadius: 99,
                            backgroundColor: 'rgba(221, 255, 85, 0.08)',
                            color: '#DDFF55'
                          }}>
                          {openInnerTableInstancId?.includes(instance?.instanceId) ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ padding: 0 }} colSpan={6}>
                        <Collapse
                          in={openInnerTableInstancId?.includes(instance?.instanceId)}
                          timeout={10}
                          unmountOnExit>
                          <VolumeDetails mountpointInfoList={instance?.mountPointInfos} />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledTableContainer>
    </>
  );
};

export default memo(PlannedVmDetailTable);
