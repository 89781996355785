import { Box, Button, Checkbox, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import CustomToolTip from 'components/StylesTooltip';
import {
  BulkOnboardInstancesInfo,
  InnerTableRowType,
  Partitions,
  ScheduleInstanceInfo,
  UnManagedVmsRes
} from 'features/scalar/components/unManagedVm/types/';
import { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  bulkRootNotSupported,
  linuxBulkNotSupported,
  nonSupportRootVolume,
  win2012Msg,
  win2019Msg,
  winLinuxCombo
} from '../../../constant';
import { useTotalSaving } from '../../../hooks/useTotalSaving';
import {
  resetBulkOnboardSchedulePayload,
  setBulkOnboardSchedulePayload,
  setMirrorMigrationData,
  setModalPreference,
  setScheduleModalProps
} from '../../../store';
import { getMountPointTrim, addSecondsToEpoch } from '../../../utils/helper';

const InnerTableRow: FC<InnerTableRowType> = ({
  instance,
  instanceIndex,
  setOnboardingGroup,
  onboardingGroup,
  selectedPartition,
  setSelectedPartition
}) => {
  const dispatch = useAppDispatch();
  const { unmanagedVmsData, scheduleModalPropsList, bulkOnboardSchedulePayload } = useAppSelector(
    (state) => state.unmanagedVm
  );
  const {
    getTotalSavingAdd,
    getTotalSavingSub,
    getTotalSavingWithConversionAdd,
    getTotalSavingWithConversionSubstract
  } = useTotalSaving();

  const getOnboardVolumeType = (selectPartition: Partitions) => {
    if (selectPartition?.partitionLabel === 'ROOT') {
      return 'root';
    } else if (selectPartition?.customRebootBasedOnboard) {
      return 'customReboot';
    } else {
      return 'dataVolume';
    }
  };

  const replaceGbString = (size: string) => {
    if (size.includes('GB')) {
      return size.replace('GB', '');
    }
  };

  const getDiskTypeConversion = (selectedInstance: UnManagedVmsRes) => {
    if (
      selectedInstance?.instanceCompatibleForV2Onboard &&
      selectedInstance?.v2OnboardingFeatureEnabled &&
      selectedInstance?.currentDiskRequirementsForV2IconDisplay
    ) {
      return { onboardingDiskType: 'PremiumV2_LRS' };
    }
  };

  const isDiskConversionCompatible = (selectedInstance: UnManagedVmsRes) => {
    if (
      selectedInstance?.instanceCompatibleForV2Onboard &&
      selectedInstance?.v2OnboardingFeatureEnabled &&
      selectedInstance?.currentDiskRequirementsForV2IconDisplay
    ) {
      return { instanceCompatibleForDiskCoversion: true };
    } else {
      return { instanceCompatibleForDiskCoversion: false };
    }
  };

  const handleOnSelectPartition = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedPartitionIdx = Number(e.target.name);
    if (unmanagedVmsData?.data) {
      const openInstance = unmanagedVmsData?.data[instanceIndex];
      const selectedPartitionDetail = openInstance?.partitions[selectedPartitionIdx];
      if (e.target.checked) {
        /**
         * schedule modal props
         */
        dispatch(
          setScheduleModalProps({
            onboardType: getOnboardVolumeType(selectedPartitionDetail),
            onboardTimeGapInMinutes: selectedPartitionDetail?.onboardTimeGapInMinutes,
            customRebootBasedOnboard: selectedPartitionDetail?.customRebootBasedOnboard,
            totalSaving: getTotalSavingAdd(openInstance, selectedPartitionDetail),
            totalSavingWithConversion: getTotalSavingWithConversionAdd(
              openInstance,
              selectedPartitionDetail
            ),
            shrinkSupported: selectedPartitionDetail?.shrinkSupported,
            minimumTimeBeforeReboot: addSecondsToEpoch(
              selectedPartitionDetail?.minimumTimeBeforeReboot,
              15 * 60
            ),
            restartMessageDisabled: selectedPartitionDetail?.restartMessageDisabled,
            popupInfo: selectedPartitionDetail?.popupInfo,
            platformType: openInstance?.platformType,
            diskCoversionFeatureEnable: openInstance?.v2OnboardingFeatureEnabled,
            cloudProviderList: scheduleModalPropsList && scheduleModalPropsList?.cloudProviderList?.length > 0
              ? [...scheduleModalPropsList.cloudProviderList, openInstance.cloudProvider]
              : [openInstance.cloudProvider]
          })
        );
        /**
         * end
         */

        setOnboardingGroup(selectedPartitionDetail?.onboardingGroup);

        setSelectedPartition((prev: any) => [
          ...prev,
          {
            tenantId: openInstance?.tenant,
            instanceId: openInstance?.instanceId,
            mountPoint: selectedPartitionDetail?.mountPoint,
            size: replaceGbString(selectedPartitionDetail.size),
            ...getDiskTypeConversion(openInstance)
          }
        ]);

        // Schedule modal info
        if (scheduleModalPropsList && scheduleModalPropsList?.scheduleInstanceInfo?.length > 0) {
          const scheduleModalPropsListTemp = scheduleModalPropsList?.scheduleInstanceInfo
            ? [...scheduleModalPropsList.scheduleInstanceInfo]
            : [];

          const index = scheduleModalPropsListTemp.findIndex(
            (item: any) => item.instanceId === openInstance?.instanceId
          );

          if (index === -1) {
            scheduleModalPropsListTemp.push({
              instanceId: openInstance?.instanceId,
              osShortName: openInstance?.osShortName,
              mountPoint: [selectedPartitionDetail?.mountPoint],
              instanceName: openInstance?.instanceName,
              anyPartitionOnboardedToV2: openInstance?.anyPartitionOnboardedToV2,
              instanceCompatibleForV2Onboard: openInstance?.instanceCompatibleForV2Onboard,
              ...isDiskConversionCompatible(openInstance)
            });
          } else {
            const mp = scheduleModalPropsListTemp[index]?.mountPoint;
            scheduleModalPropsListTemp[index] = {
              ...scheduleModalPropsListTemp[index],
              mountPoint: mp.concat(selectedPartitionDetail?.mountPoint)
            };
          }
          dispatch(
            setScheduleModalProps({
              scheduleInstanceInfo: scheduleModalPropsListTemp
            })
          );
        } else {
          dispatch(
            setScheduleModalProps({
              scheduleInstanceInfo: [
                {
                  instanceId: openInstance?.instanceId,
                  osShortName: openInstance?.osShortName,
                  mountPoint: [selectedPartitionDetail?.mountPoint],
                  instanceName: openInstance?.instanceName,
                  anyPartitionOnboardedToV2: openInstance?.anyPartitionOnboardedToV2,
                  instanceCompatibleForV2Onboard: openInstance?.instanceCompatibleForV2Onboard,
                  ...isDiskConversionCompatible(openInstance)
                }
              ]
            })
          );
        }
        //end of schedule modal props
      } else {
        setSelectedPartition((prev: any[]) =>
          prev.filter(
            (itm) =>
              itm.instanceId !== openInstance?.instanceId ||
              itm.mountPoint !== selectedPartitionDetail?.mountPoint
          )
        );

        // shcedule modal props
        if (scheduleModalPropsList && scheduleModalPropsList?.scheduleInstanceInfo?.length > 0) {
          const scheduleModalPropsListTemp = scheduleModalPropsList?.scheduleInstanceInfo
            ? [...scheduleModalPropsList.scheduleInstanceInfo]
            : [];

          const index = scheduleModalPropsListTemp.findIndex(
            (item: any) => item.instanceId === openInstance?.instanceId
          );
          if (index !== -1) {
            const mp = scheduleModalPropsListTemp[index]?.mountPoint?.filter(
              (mps) => mps !== selectedPartitionDetail?.mountPoint
            );

            if (mp?.length > 0) {
              scheduleModalPropsListTemp[index] = {
                ...scheduleModalPropsListTemp[index],
                mountPoint: mp
              };
            } else {
              scheduleModalPropsListTemp?.splice(index, 1);
            }
          }
          dispatch(
            setScheduleModalProps({
              cloudProviderList: scheduleModalPropsList.cloudProviderList.filter(
                (_, index, self) => self.indexOf(openInstance.cloudProvider) !== index
              ),
              scheduleInstanceInfo: scheduleModalPropsListTemp,
              totalSaving: getTotalSavingSub(openInstance, selectedPartitionDetail),
              totalSavingWithConversion: getTotalSavingWithConversionSubstract(
                openInstance,
                selectedPartitionDetail
              )
            })
          );
        }
      }

      dispatch(
        setBulkOnboardSchedulePayload({
          supportingScheduleInfo: {
            rebootRequired: selectedPartitionDetail?.customRebootBasedOnboard,
            cleanupDelayDays: 3
          },
          osType: openInstance?.platformType
        })
      );
    }
  };

  useEffect(() => {
    if (selectedPartition.length === 0) {
      setOnboardingGroup('');
      dispatch(resetBulkOnboardSchedulePayload());
    }
  }, [selectedPartition]);

  const disableCheckBox = (partition: Partitions, clickInstance: UnManagedVmsRes) => {
    if (clickInstance?.onboardingSupported === false) {
      return true;
    }
    if (partition?.onboardingSupported === false || partition?.status === 'Onboard Scheduled') {
      return true;
    }
    if (!onboardingGroup || onboardingGroup === partition?.onboardingGroup) {
      return false;
    } else {
      return true;
    }
  };

  const checkPartitionStatus = (status: string) => {
    return (
      status === 'Onboard Scheduled' ||
      status === 'In Progress' ||
      status === 'Queued for Onboarding'||
      status ==='Finalizing Onboarding'
    );
  };

  const checkSelectedPartition = (partitions: Partitions) => {
    const openInstance = unmanagedVmsData?.data
      ? unmanagedVmsData?.data[instanceIndex]
      : { instanceId: '' };
    const partitionMp = openInstance?.instanceId + '_' + partitions?.mountPoint;

    if (bulkOnboardSchedulePayload?.mountPointInfo) {
      return bulkOnboardSchedulePayload?.mountPointInfo?.some((mp: BulkOnboardInstancesInfo) => {
        const bulkMpIid = mp?.instanceId + '_' + mp?.mountPoint;
        return partitionMp === bulkMpIid || checkPartitionStatus(partitions?.status);
      });
    } else {
      return checkPartitionStatus(partitions?.status);
    }
  };

  const checkSelectedOsName = (compare: string) => {
    return scheduleModalPropsList?.scheduleInstanceInfo?.findIndex((itm: ScheduleInstanceInfo) => {
      return itm.osShortName?.includes(compare);
    });
  };

  const getCheckBoxDisabledToolTipMessage = (
    partition: Partitions,
    clickInstance: UnManagedVmsRes
  ) => {
    if (clickInstance?.platformType === 'Windows') {
      if (scheduleModalPropsList?.platformType === 'Linux') {
        return winLinuxCombo;
      }
    } else if (clickInstance?.platformType === 'Linux') {
      if (scheduleModalPropsList?.platformType === 'Windows') {
        return winLinuxCombo;
      }
    }

    if (scheduleModalPropsList?.onboardType === 'root' || partition?.partitionLabel === 'ROOT') {
      if (scheduleModalPropsList?.onboardType === 'root' && partition?.partitionLabel === 'ROOT') {
        return bulkRootNotSupported;
      }
      return nonSupportRootVolume;
    }

    if (clickInstance?.platformType === 'Windows') {
      if (clickInstance?.osShortName?.includes('2019') || checkSelectedOsName('2019') !== -1) {
        if (checkSelectedOsName('2019') !== -1 && partition?.partitionLabel === 'ROOT') {
          return bulkRootNotSupported;
        }
        return win2019Msg;
      } else if (instance?.osShortName?.includes('2012') || checkSelectedOsName('2012') !== -1) {
        if (checkSelectedOsName('2012') !== -1 && partition?.partitionLabel === 'ROOT') {
          return bulkRootNotSupported;
        }
        return win2012Msg;
      }
    } else if (clickInstance?.platformType === 'Linux') {
      if (scheduleModalPropsList?.platformType === 'Linux') {
        if (partition?.partitionLabel === 'ROOT') {
          return bulkRootNotSupported;
        }
        return linuxBulkNotSupported;
      }
    }
  };

  const toolTipMsgToShow = (partition: Partitions, clickInstance: UnManagedVmsRes) => {
    if (partition?.partitionStatusInfoWithPlaceHolder?.messageWithPlaceHolders) {
      return partition?.partitionStatusInfoWithPlaceHolder?.messageWithPlaceHolders;
    } else if (partition?.status === 'Onboard Scheduled') {
      return partition?.status;
    } else if (disableCheckBox(partition, clickInstance)) {
      if (clickInstance?.onboardingSupported === false) {
        return 'Not supported';
      } else {
        return getCheckBoxDisabledToolTipMessage(partition, clickInstance);
      }
    } else {
      return partition?.partitionStatusInfoWithPlaceHolder?.messageWithPlaceHolders;
    }
  };

  const handleViewProgressDetail = (partition: Partitions, clickedinstance: UnManagedVmsRes) => {
    dispatch(
      setMirrorMigrationData({
        payload: {
          tenantId: clickedinstance?.tenant,
          instanceId: clickedinstance?.instanceId,
          mountPoint: partition?.mountPoint
        },
        props: {
          instanceName: clickedinstance?.instanceName,
          fullOsName: clickedinstance?.fullOsName
        }
      })
    );
    dispatch(setModalPreference('mirrorMigration'));
  };

  return (
    <TableBody>
      {instance?.partitions.map((partition: Partitions, partitionIndex: number) => {
        const disableCheckbox = disableCheckBox(partition, instance);
        return (
          <TableRow
            key={partition?.mountPoint + partition?.size}
            sx={{
              '.MuiTableCell-root': {
                width: 250,
                pl: '2rem'
              },
              '> .MuiTableCell-root': {
                color: disableCheckbox ? 'rgba(255, 255, 255, 0.5)' : 'white'
              }
            }}>
            <TableCell
              sx={{ pl: '66px !important', position: 'relative' }}
              data-testid={instance?.instanceName + '_' + partition?.mountPoint}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CustomToolTip
                  style={{ display: 'flex', alignItems: 'center' }}
                  left={16}
                  top={16}
                  right={300}
                  showIcon={false}
                  text={toolTipMsgToShow(partition, instance)}>
                  <Checkbox
                    sx={{
                      '&.Mui-disabled': {
                        pointerEvents: 'all'
                      }
                    }}
                    name={`${partitionIndex}`}
                    disabled={disableCheckbox}
                    checked={checkSelectedPartition(partition)}
                    onChange={handleOnSelectPartition}
                  />
                  <Typography
                    variant="body2"
                    fontSize={16}
                    color={`${disableCheckbox ? 'gray' : 'white'}`}
                    sx={{
                      inlineSize: '260px',
                      overflowWrap: 'break-word',
                      textOverflow: 'ellipsis'
                    }}>
                    {getMountPointTrim(partition?.mountPoint)}
                  </Typography>
                </CustomToolTip>
              </Box>
            </TableCell>
            <TableCell align="left">
              {partition?.utilization}
              <Typography variant="body2" fontSize={16} color="gray">
                {partition?.absoluteUsage}
              </Typography>
            </TableCell>
            <TableCell align="left">{partition?.unrealizedSavings}</TableCell>
            <TableCell align="left">
              <Box sx={{ display: 'flex', gap: '10px' }}>
                <Typography
                  variant="body2"
                  gutterBottom
                  fontSize={15}
                  mt={0.4}
                  lineHeight="20px"
                  color="gray">
                  {partition?.status}
                </Typography>
                {partition?.migrationPercentageCompletion && (
                  <Box
                    sx={{
                      padding: '4px',
                      backgroundColor: 'rgba(221, 255, 85, 0.08)'
                    }}>
                    <Typography variant="body2" fontSize={12} lineHeight="14px" color="primary">
                      {partition?.migrationPercentageCompletion}
                    </Typography>
                  </Box>
                )}
              </Box>
              {partition?.migrationPercentageCompletion && (
                <Button
                  variant="text"
                  sx={{ color: '#DDFF55', fontSize: '14px', p: 0, height: '12px' }}
                  onClick={() => handleViewProgressDetail(partition, instance)}>
                  View Details
                </Button>
              )}
            </TableCell>
            <TableCell
              sx={{
                '&.MuiTableCell-root': {
                  minWidth: '10px !important',
                  maxWidth: '10px !important'
                }
              }}></TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default InnerTableRow;
